<template>
  <div>
    <v-system-bar color="warning">Ferien</v-system-bar>
    <v-card-text class="title pt-8 pb-0 text-center">
      {{ name }}
    </v-card-text>
    <v-card-text class="subtitle pt-0 text-center">
      {{ formatDatespan }}
    </v-card-text>
    <v-card-text class="display text-center">
      <v-avatar :color="value.color ? value.color : 'warning'">
        <v-icon color="white">{{ holidayIcon }}</v-icon>
      </v-avatar>
    </v-card-text>
    <v-list-item
      v-bind="$attrs"
      :class="active ? 'active' : ''"
      v-if="$root.settings.showIcal"
    >
      <v-list-item-icon>
        <v-icon color="warning">{{ holidayIcon }}</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ name }}</v-list-item-title>
        <v-list-item-subtitle>{{ formatDatespan }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          outlined
          text
          color="info"
          small
          @click="apiIcal('holiday', value.id)"
          ><v-icon left>mdi-calendar-import</v-icon> iCal</v-btn
        >
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<script>
import { formatDatespan } from "common/utils/date";
import { holidayIcon } from "common/utils/icons";
export default {
  name: "HolidayDetail",
  props: {
    active: {
      type: Boolean,
      default: () => false,
    },
    value: null,
  },
  computed: {
    name() {
      if (!this.value.holiday) {
        return "";
      }
      return this.value.holiday.name;
    },
    holidayIcon() {
      return holidayIcon(this.value.holiday, true);
    },
    formatDatespan() {
      if (!this.value) {
        return "";
      }
      return formatDatespan(
        this.value.holiday.startDate,
        this.value.holiday.endDate
      );
    },
  },
};
</script>

<style scoped>
.active {
  border-left: 2px solid red;
}
</style>
